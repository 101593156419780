import "./CssLoader.css"
import React from "react"
import { Box, Center, SimpleGrid } from "@chakra-ui/react"

interface Props {
  label?: string
}

const CssLoader: React.FC<Props> = ({ label }) => {
  return (
    <Box width={56} my={[8, 24]}>
      {label && (
        <Center mb={3} textColor="gray.500">
          {label}
        </Center>
      )}
      <SimpleGrid className="css-loader" spacing={2} columns={5}>
        <Center>
          <Box className="item"></Box>
        </Center>
        <Center>
          <Box className="item"></Box>
        </Center>
        <Center>
          <Box className="item"></Box>
        </Center>
        <Center>
          <Box className="item"></Box>
        </Center>
        <Center>
          <Box className="item"></Box>
        </Center>
      </SimpleGrid>
    </Box>
  )
}

export default CssLoader
