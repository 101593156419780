import { Box } from "@chakra-ui/react"
import React from "react"
import { ScrollElement } from "react-scroll"

interface Props {
  name: string
  children: React.ReactNode
}

const ScrollElementWrapper: React.FC<Props> = ({ name, children, ...rest }) => {
  const { parentBindings, ...props } = rest as any
  return (
    <Box name={name} {...props}>
      {children}
    </Box>
  )
}

export default ScrollElement(ScrollElementWrapper)
