import { EntityId, PrintServiceProductEntityHydrated } from "@jackfruit/common"
import { useSelector } from "react-redux"
import { printServiceProductsSelector } from "~/redux/state/printServiceProducts"
import { RootState } from "~/redux/store"

export const useProducts = (ids: EntityId[]) => {
  const products = useSelector<RootState, PrintServiceProductEntityHydrated[]>(
    (state: RootState) => printServiceProductsSelector.selectByIds(state, ids)
  )

  return { products }
}
