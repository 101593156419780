import {
  BlockCheckoutEntityHydrated,
  PageEntityHydrated,
} from "@jackfruit/common"
import loadable from "@loadable/component"
import React from "react"
import { useCart } from "~/hooks/useCart"
import { useLineItems } from "~/hooks/useLineItems"

const CheckoutBlock = loadable(() => import("./CheckoutBlock"))

interface Props {
  config: BlockCheckoutEntityHydrated
  pages: PageEntityHydrated[]
}

const CheckoutBlockWrapper: React.FC<Props> = props => {
  const { cart } = useCart()
  const { lineItems } = useLineItems(cart.lineItemIds)
  const { fulfillment, storeId: selectedStoreId } = cart

  const atLeastOneLineItemIsReady = lineItems.some(({ isReady }) => isReady)

  // if all lines items are not ready, then bypass
  // if at least one line item is ready, then show
  if (!atLeastOneLineItemIsReady) {
    return null
  }

  // if fulfillment is pickup but no store is selected
  // then bypass this component
  if (fulfillment === "pickup" && !selectedStoreId) {
    return null
  }

  return <CheckoutBlock {...props} />
}

export default CheckoutBlockWrapper
