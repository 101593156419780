import { ListItem, UnorderedList } from "@chakra-ui/react"
import { EntityId } from "@reduxjs/toolkit"
import { uniq } from "lodash"
import React from "react"
import { useLineItems } from "~/hooks/useLineItems"
import { useProducts } from "~/hooks/useProducts"
import ConfirmationModal from "../ConfirmationModal"

interface Props {
  title: string
  description: string
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  lineItemsToKeep: EntityId[]
  lineItemsToRemove: EntityId[]
}

const FulfillmentUpdateConfirmationDialog: React.FC<Props> = ({
  title,
  description,
  isOpen,
  onClose,
  onConfirm,
  lineItemsToKeep,
  lineItemsToRemove,
}) => {
  const { lineItems } = useLineItems(lineItemsToRemove)
  const allProductIds = uniq(lineItems.map(lineItem => lineItem.productId))
  const { products } = useProducts(allProductIds)

  const listOfProductUnavailable = products
    .map(product => product.shortDescription)
    .sort()

  return (
    <ConfirmationModal
      title={title}
      description={description}
      onClose={onClose}
      onConfirm={onConfirm}
      isOpen={isOpen}
      id="fulfillment"
      data-testid="fulfillment-confirm-dialog"
    >
      {lineItemsToKeep.length > 0 && (
        <UnorderedList pl={6} mt={3}>
          {listOfProductUnavailable.map(label => {
            return <ListItem key={label}>{label}</ListItem>
          })}
        </UnorderedList>
      )}
    </ConfirmationModal>
  )
}

export default FulfillmentUpdateConfirmationDialog
