import { useSelector } from "react-redux"
import { clientsSelector } from "~/redux/state/client"

export const useDefaultClient = () => {
  const clients = useSelector(clientsSelector.selectAll)

  return {
    client: clients[0], // there is always exactly one client
  }
}
