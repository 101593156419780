import { EntityId } from "@jackfruit/common"
import { useSelector } from "react-redux"
import { ordersSelector } from "~/redux/state/orders"
import { RootState } from "~/redux/store"

export const useOrder = (id: EntityId) => {
  const order = useSelector((state: RootState) =>
    ordersSelector.selectById(state, id)
  )!

  return { order }
}
