import { BlockOrderCompleteEntityHydrated } from "@jackfruit/common"
import loadable from "@loadable/component"
import { navigate } from "gatsby"
import React, { useEffect } from "react"
import { useApplicationState } from "~/hooks/useApplicationState"
import { useOrder } from "~/hooks/useOrder"

const OrderCompleteBlock = loadable(() => import("./OrderCompleteBlock"))

interface Props {
  config: BlockOrderCompleteEntityHydrated
}

const OrderCompleteBlockWrapper: React.FC<Props> = ({ config }) => {
  const { lastOrderId: currentOrderId } = useApplicationState()
  const { order: placedOrder } = useOrder(currentOrderId)

  useEffect(() => {
    if (!placedOrder) {
      navigate("/")
    }
  }, [placedOrder])

  if (!placedOrder) {
    return null
  }

  return <OrderCompleteBlock config={config} />
}

export default OrderCompleteBlockWrapper
