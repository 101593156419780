import loadable from "@loadable/component"
import React from "react"
import { useBlocks } from "~/hooks/useBlocks"
import { useUploads } from "~/hooks/useUploads"

const PhotosUploadedBlock = loadable(() => import("./PhotosUploadedBlock"))

interface Props {}

const PhotosUploadedBlockWrapper: React.FC<Props> = () => {
  const { uploads } = useUploads()
  const { blocks } = useBlocks()

  const hasImageUploadBlock = !blocks.find(
    ({ type }) => type === "image-upload"
  )
  if (uploads.length === 0 || hasImageUploadBlock) {
    return null
  }

  return <PhotosUploadedBlock />
}

export default PhotosUploadedBlockWrapper
