import { Center, Heading, Text } from "@chakra-ui/react"
import { BlockTemplateEntityHydrated } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import CssLoader from "../CssLoader"

interface Props {
  config: BlockTemplateEntityHydrated
}

const TemplateBlockLoader: React.FC<Props> = ({ config }) => {
  const { t } = useTranslation()
  const { blockId, title, subtitle } = config

  return (
    <>
      {Boolean(title) && (
        <Heading
          id={`template-${blockId}-title`}
          textAlign="center"
          mt={8}
          mb={subtitle ? 2 : 12}
          fontSize="2xl"
          fontFamily="heading"
          fontWeight="semibold"
          color="gray.600"
          lineHeight="short"
        >
          {title}
        </Heading>
      )}
      {Boolean(subtitle) && (
        <Text
          id={`template-${blockId}-subtitle`}
          textAlign="center"
          maxW="800px"
          mx="auto"
          mb={12}
          mt={title ? 0 : 8}
        >
          {subtitle}
        </Text>
      )}
      <Center>
        <CssLoader label={t("components.blocks.TemplateBlockA.Loading")} />
      </Center>
    </>
  )
}

export default TemplateBlockLoader
