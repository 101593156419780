import { EntityId } from "@jackfruit/common"
import { useSelector } from "react-redux"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import { lineItemsSelectors } from "~/redux/state/lineItems"
import { RootState } from "~/redux/store"

export const useLineItems = (
  ids: EntityId[]
): { lineItems: LineItemEntity[] } => {
  const lineItems = useSelector<RootState, LineItemEntity[]>(state =>
    lineItemsSelectors.selectByIds(state, ids)
  )

  return { lineItems }
}
