import { useSelector } from "react-redux"
import {
  EntityId,
  PrintServiceProductImageEntityHydrated,
} from "@jackfruit/common"
import { printServiceProductImagesSelector } from "~/redux/state/printServiceProductImages"
import { RootState } from "~/redux/store"
import { useProduct } from "./useProduct"

export const useProductImages = (productId: EntityId) => {
  const { product } = useProduct(productId)
  const productImages = useSelector((state: RootState) =>
    printServiceProductImagesSelector.selectByIds(
      state,
      product?.printServiceProductImages || []
    )
  )?.filter(
    value => value !== undefined
  ) as PrintServiceProductImageEntityHydrated[]

  return { productImages }
}
