import { BlockTemplateEntityHydrated } from "@jackfruit/common"
import loadable from "@loadable/component"
import React from "react"
import { usePageCart } from "~/hooks/usePageCart"
import { usePageSession } from "~/hooks/usePageSession"
import Wrapper from "../Wrapper"
import TemplateBlockLoader from "./TemplateBlockLoader"

const TemplateBlock = loadable(() => import("./TemplateBlock"))

interface Props {
  config: BlockTemplateEntityHydrated
}

const TemplateBlockWrapper: React.FC<Props> = ({ config }) => {
  const { pageSession } = usePageSession()
  const { pageCart } = usePageCart()
  const { fulfillment } = pageCart

  const availablePrintServicesForFulfillment = config.printServices[
    fulfillment
  ].map(data => data.id)

  if (availablePrintServicesForFulfillment.length === 0) {
    console.error(
      `No print services available for fulfillment [${fulfillment}], unable to load templates`
    )
  }

  // cannot show the template block
  // before the page session is marked as ready
  // as the template tags will not yet available
  const canShowTemplateBlock =
    availablePrintServicesForFulfillment.length !== 0 && pageSession.isReady

  const component = canShowTemplateBlock ? (
    <TemplateBlock config={config} />
  ) : (
    <TemplateBlockLoader config={config} />
  )

  return (
    <Wrapper pt={16} pb={12} minH="500px" hasSeparator={true}>
      {component}
    </Wrapper>
  )
}

export default TemplateBlockWrapper
