import { Flex, Image } from "@chakra-ui/react"
import { BlockImageGridEntityHydrated } from "@jackfruit/common"
import React from "react"
import Wrapper from "../Wrapper"

interface Props {
  config: BlockImageGridEntityHydrated
}

const ImageGridBlock: React.FC<Props> = ({ config }) => {
  const { alignment, alignmentXs, columnWidth, columnWidthXs, assets } = config

  const getAlignment = (alignment: number) => {
    let alignItems = "center"
    switch (alignment) {
      case 1:
        alignItems = "flex-start"
        break
      case 2:
        alignItems = "flex-end"
        break
    }
    return alignItems
  }

  return (
    <Wrapper
      py={20}
      hasSeparator={true}
      display="flex"
      justifyContent={[getAlignment(alignmentXs), getAlignment(alignment)]}
      alignItems="center"
      flexWrap="wrap"
    >
      {assets.map(asset => (
        <Flex
          key={asset.id}
          w={[`${columnWidthXs}%`, `${columnWidth}%`]}
          alignItems="center"
          justifyContent="center"
          padding="12px"
        >
          <Image
            maxWidth="100%"
            key={asset.id}
            src={asset.path}
            alt={asset.alt}
          />
        </Flex>
      ))}
    </Wrapper>
  )
}

export default ImageGridBlock
