import { AppEntityHydrated, BlockHtmlEntityHydrated } from "@jackfruit/common"
import React from "react"
import HtmlContent from "../HtmlContent"

interface Props {
  config: BlockHtmlEntityHydrated
  appConfig: AppEntityHydrated
}

const HtmlBlock: React.FC<Props> = ({ config, appConfig }) => {
  const { html } = config

  return <HtmlContent html={html} config={appConfig} />
}

export default HtmlBlock
