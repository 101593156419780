import { Box, Heading, HStack, VStack } from "@chakra-ui/react"
import { AppEntityHydrated, BlockSocialMediaEntity } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { FaFacebookF } from "react-icons/fa"
import { FiInstagram, FiTwitter, FiYoutube } from "react-icons/fi"
import { useApplicationState } from "~/hooks/useApplicationState"
import { useDefaultClient } from "~/hooks/useDefaultClient"
import { replaceShortCodes } from "~/services/replaceShortCodes"
import SocialMediaLink from "../SocialMediaLInk"
import Wrapper from "../Wrapper"

export interface Props {
  config: BlockSocialMediaEntity
  appConfig: AppEntityHydrated
}

const SocialMediaBlock: React.FC<Props> = ({ config, appConfig }) => {
  const { t } = useTranslation()
  const defaultTitle = t("components.blocks.SocialMediaBlock.Title")
  const defaultBody = t("components.blocks.SocialMediaBlock.Body", {
    supportEmail: "[link field=supportEmail]",
  })
  const { facebookUrl, instagramUrl, twitterUrl, youtubeUrl } =
    useApplicationState()
  const { client } = useDefaultClient()

  const { title = defaultTitle, body = defaultBody } = config
  const displayTitle = title && title.length ? title : defaultTitle
  const displayBody = body && body.length ? body : defaultBody
  const processedContent = replaceShortCodes(displayBody, { appConfig, client })

  return (
    <Box bg="gray.200">
      <Wrapper py={8} textAlign={["left", "center", "center"]}>
        <VStack spacing={4} alignItems={["start", "center", "center"]}>
          <Heading as="h3" fontSize="2xl">
            {displayTitle}
          </Heading>
          <Box
            fontSize="normal"
            dangerouslySetInnerHTML={{ __html: processedContent }}
          />

          <HStack spacing={4} alignItems="center" justifyContent="center">
            {facebookUrl && (
              <SocialMediaLink
                icon={FaFacebookF}
                link={facebookUrl}
                bg="#3B5998"
              />
            )}

            {instagramUrl && (
              <SocialMediaLink
                icon={FiInstagram}
                link={instagramUrl}
                bg="radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)"
              />
            )}

            {twitterUrl && (
              <SocialMediaLink
                icon={FiTwitter}
                link={twitterUrl}
                bg="#1DA1F2"
              />
            )}

            {youtubeUrl && (
              <SocialMediaLink icon={FiYoutube} link={youtubeUrl} bg="red" />
            )}
          </HStack>
        </VStack>
      </Wrapper>
    </Box>
  )
}

export default SocialMediaBlock
