import React from "react"
import { Button, Flex, Heading } from "@chakra-ui/react"
import ErrorPanel from "./ErrorPanel"
import { useTranslation } from "react-i18next"

interface ErrorFallbackProps {
  error: Error
  resetErrorBoundary?: (args: any) => void
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  error,
  resetErrorBoundary = null,
}) => {
  const { t } = useTranslation()

  return (
    <Flex direction="column" alignItems="center">
      <Heading as="h1" mb={3}>
        {t("components.ErrorFallback.Heading")}
      </Heading>
      <ErrorPanel error={error.message}></ErrorPanel>
      {resetErrorBoundary && (
        <Button
          colorScheme="primary"
          color="primary.text"
          onClick={resetErrorBoundary}
        >
          {t("components.ErrorFallback.Action")}
        </Button>
      )}
    </Flex>
  )
}

export default ErrorFallback
