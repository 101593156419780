import {
  Button,
  Flex,
  HStack,
  IconButton,
  Text,
  useUpdateEffect,
} from "@chakra-ui/react"
import { clamp, range } from "lodash"
import React, { useCallback, useEffect, useState } from "react"
import { FiChevronLeft, FiChevronRight } from "react-icons/fi"

const pagePadding = 2

interface Props {
  totalItems: number
  itemsPerPages: number
  selectedPage?: number
  onPageChange?: (page: number) => void
}

const Pagination: React.FC<Props> = ({
  totalItems,
  itemsPerPages,
  selectedPage = 0,
  onPageChange,
}) => {
  const [currentPage, setCurrentPage] = useState(selectedPage)

  useEffect(() => {
    setCurrentPage(selectedPage)
  }, [selectedPage])

  const handlePageClick = useCallback((page: number) => {
    setCurrentPage(page)
  }, [])

  const handlePreviousClick = useCallback(() => {
    setCurrentPage(page => page - 1)
  }, [])

  const handleNextClick = useCallback(() => {
    setCurrentPage(page => page + 1)
  }, [])

  useUpdateEffect(() => {
    onPageChange?.(currentPage)
  }, [onPageChange, currentPage])

  const totalPages = Math.ceil(totalItems / itemsPerPages)

  if (totalPages === 1) {
    return null
  }

  const startVisibility = clamp(currentPage - pagePadding, 0, totalPages)
  const endVisibility = clamp(currentPage + pagePadding + 1, 0, totalPages)

  const showStartHyphens = currentPage > pagePadding
  const showEndHyphens = totalPages - 1 - currentPage > pagePadding

  const rangeToShow = range(startVisibility, endVisibility)

  const isPreviousDisabled = currentPage === 0
  const isNextDisabled = currentPage === totalPages - 1

  return (
    <HStack>
      <IconButton
        id="previous-page"
        aria-label="previous-page"
        onClick={handlePreviousClick}
        variant="ghost"
        colorScheme="primary"
        icon={<FiChevronLeft />}
        _hover={{ bg: "transparent" }}
        _active={{ bg: "transparent" }}
        isDisabled={isPreviousDisabled}
      />
      {showStartHyphens && <Text color="gray.400">...</Text>}
      {rangeToShow.map(index => {
        return (
          <Flex key={index}>
            <Button
              id={`pagination--${index + 1}`}
              onClick={() => handlePageClick(index)}
              height="40px"
              width="40px"
              p={0}
              fontSize="0.8em"
              rounded="full"
              variant="ghost"
              colorScheme="primary"
              isActive={index === currentPage}
              _active={{
                borderColor: "primary.500",
                borderWidth: 2,
                bg: "primary.50",
                boxShadow: "md",
              }}
            >
              <Text>{index + 1}</Text>
            </Button>
          </Flex>
        )
      })}
      {showEndHyphens && <Text color="gray.400">...</Text>}
      <IconButton
        id="next-page"
        aria-label="next-page"
        onClick={handleNextClick}
        variant="ghost"
        colorScheme="primary"
        icon={<FiChevronRight />}
        _hover={{ bg: "transparent" }}
        _active={{ bg: "transparent" }}
        isDisabled={isNextDisabled}
      />
    </HStack>
  )
}

export default Pagination
