import { BlockCartEntityHydrated } from "@jackfruit/common"
import loadable from "@loadable/component"
import React, { useEffect } from "react"
import { useCart } from "~/hooks/useCart"
import { usePageSession } from "~/hooks/usePageSession"
import { alertUserBeforeLeaving } from "~/services/Utils"

const CartBlock = loadable(() => import("./CartBlock"))

interface Props {
  config: BlockCartEntityHydrated
}

const CartBlockWrapper: React.FC<Props> = props => {
  const { pageSession } = usePageSession()
  const { cart } = useCart()
  const { storeId, fulfillment } = cart

  const lineItemCount = cart.lineItemIds.length
  const flow = pageSession.pageFlow

  useEffect(() => {
    if (process.env.GATSBY_ACTIVE_ENV !== "dev") {
      if (lineItemCount > 0) {
        window.addEventListener("beforeunload", alertUserBeforeLeaving)
      }

      return () => {
        window.removeEventListener("beforeunload", alertUserBeforeLeaving)
      }
    }
  }, [lineItemCount])

  if (lineItemCount === 0) {
    return null
  }

  if (fulfillment === "pickup" && flow === "store-first" && !storeId) {
    return null
  }

  return <CartBlock {...props} />
}

export default CartBlockWrapper
