import {
  BlockFulfillmentEntityHydrated,
  PageEntityHydrated,
} from "@jackfruit/common"
import React from "react"
import { useCart } from "~/hooks/useCart"
import { usePageSession } from "~/hooks/usePageSession"
import FulfillmentBlock from "./FulfillmentBlock"

interface Props {
  config: BlockFulfillmentEntityHydrated
  page: PageEntityHydrated
}

const FulfillmentBlockWrapper: React.FC<Props> = props => {
  const { page } = props
  const { cart } = useCart()
  const { pageSession } = usePageSession(page.id)
  const { isDeliveryEnabled, isPickupEnabled } = page
  const lineItemCount = cart.lineItemIds.length
  const fulfillment = cart.fulfillment
  const hasMultipleFulfillments = isDeliveryEnabled && isPickupEnabled

  const fulfillmentIndex =
    page.blocks.find(b => b.type === "fulfillment")?.order ?? 0
  const productBlockIndex =
    page.blocks.find(b =>
      ["image-upload", "template", "product-grid"].includes(b.type)
    )?.order ?? 0
  const firstCondition =
    fulfillment === "pickup" &&
    lineItemCount > 0 &&
    !pageSession.hasLoadedStores

  const secondCondition = lineItemCount > 0
  const thirdCondition = fulfillmentIndex < productBlockIndex

  const shouldShow = firstCondition || secondCondition || thirdCondition

  if (!hasMultipleFulfillments || !shouldShow) {
    return null
  }

  return <FulfillmentBlock {...props} />
}

export default FulfillmentBlockWrapper
