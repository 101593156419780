import { useSelector } from "react-redux"
import { EntityId, PrintServiceProductEntityHydrated } from "@jackfruit/common"
import { printServiceProductsSelector } from "~/redux/state/printServiceProducts"
import { RootState } from "~/redux/store"

export const useProduct = (id: EntityId) => {
  const product = useSelector((state: RootState) =>
    printServiceProductsSelector.selectById(state, id)
  ) as PrintServiceProductEntityHydrated

  return { product }
}
