import React from "react"
import { Box } from "@chakra-ui/react"
import { BlockRichTextEntityHydrated } from "@jackfruit/common"
import "./RichTextBlock.scss"

interface Props {
  config: BlockRichTextEntityHydrated
}

const RichTextBlock: React.FC<Props> = ({ config }) => {
  const { html } = config
  return (
    <Box
      as="article"
      className="rich-text-content"
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

export default RichTextBlock
