import { BlockStoreSearchEntityHydrated } from "@jackfruit/common"
import loadable from "@loadable/component"
import React from "react"
import { useCart } from "~/hooks/useCart"
import { useLineItems } from "~/hooks/useLineItems"
import { usePageSession } from "~/hooks/usePageSession"

const StoreSearchBlock = loadable(() => import("./StoreSearchBlock"))

interface Props {
  config: BlockStoreSearchEntityHydrated
}

const StoreSearchBlockWrapper: React.FC<Props> = props => {
  const { pageSession } = usePageSession()
  const { cart } = useCart()
  const { lineItems } = useLineItems(cart.lineItemIds)

  const lineItemCount = cart.lineItemIds.length
  const atLeastOneLineItemIsReady =
    lineItemCount > 0 && lineItems.some(({ isReady }) => isReady)

  const fulfillment = cart.fulfillment

  // fulfillment is pickup flow is store first,
  // no stores has been searched yet
  // but we still want to show the store search as some
  // uploads are pending a decision in the page session
  // and no stores has been searched yet
  const storeFirstPickupPending =
    fulfillment === "pickup" &&
    pageSession.uploadIds.length > 0 &&
    pageSession.pageFlow === "store-first" &&
    !pageSession.hasLoadedStores

  // this is pickup and we have line items, we show the block
  const storeFirstPickupLoaded =
    pageSession.pageFlow === "store-first" &&
    fulfillment === "pickup" &&
    lineItemCount > 0

  // this is product first, we show when all line items are ready
  const productFirst =
    pageSession.pageFlow === "product-first" &&
    fulfillment === "pickup" &&
    atLeastOneLineItemIsReady

  const showStoreSearch =
    storeFirstPickupPending || storeFirstPickupLoaded || productFirst

  if (!showStoreSearch) {
    return null
  }

  return <StoreSearchBlock {...props} />
}

export default StoreSearchBlockWrapper
