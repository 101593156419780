import { Button, Container, Flex, Heading, Stack, Text } from "@chakra-ui/react"
import { FulfillmentTypes } from "@jackfruit/common"
import React from "react"
import { BiStoreAlt } from "react-icons/bi"
import { FiCheck, FiTruck } from "react-icons/fi"
import { Gtm } from "~/services/Gtm"

interface Props {
  title: any
  description: any
  svgIcon: any
  index: number
  collectChoice: number
  containerBgColor: string
  fontColor: string
  checkBgColor: string
  fulfillmentType: FulfillmentTypes
  onSetChoice: (fullfilment: FulfillmentTypes) => void
  isDisabled?: boolean
}

const CardButton: React.FC<Props> = ({
  index,
  title,
  description,
  svgIcon,
  collectChoice,
  onSetChoice,
  containerBgColor,
  fontColor,
  checkBgColor,
  fulfillmentType,
  isDisabled = false,
}) => {
  const getBackground = () => {
    if (typeof index === "undefined") {
      return containerBgColor
    }
    return index === collectChoice ? "#ffffff1a" : "transparent"
  }

  const getHoverColor = () => {
    return typeof index === "undefined" ? "#3a3a3a" : "#ffffff1a"
  }

  const tagName =
    fulfillmentType === "pickup"
      ? "p-fulfillment-pickup"
      : "p-fulfillment-delivery"

  const setChoice = () => {
    // GTM: work around - instead of attaching the id to every sub-element,
    // fire custom click event
    Gtm.fireCustomClickEvent("", tagName)
    onSetChoice(fulfillmentType)
  }

  return (
    <Button
      id={tagName}
      w="100%"
      p="25px"
      px={4}
      py={6}
      borderRadius="lg"
      display="block"
      height="auto"
      textAlign="left"
      whiteSpace="normal"
      color={fontColor}
      bg={getBackground()}
      onClick={setChoice}
      isDisabled={isDisabled}
      _hover={{ bg: getHoverColor() }}
    >
      <Stack direction="row" spacing={4}>
        <Container p="0" flex="0 0 20px" marginTop="2px">
          {svgIcon && <div dangerouslySetInnerHTML={{ __html: svgIcon }}></div>}
          {!svgIcon && !fulfillmentType && <BiStoreAlt size={20} />}
          {!svgIcon && fulfillmentType === "pickup" && <BiStoreAlt size={20} />}
          {!svgIcon && fulfillmentType === "delivery" && <FiTruck size={20} />}
        </Container>
        <Container p="0">
          <Heading as="h4" fontSize="lg" marginBottom={2} color="white">
            {title}
          </Heading>
          <Text opacity=".75" lineHeight="base" color="white">
            {description}
          </Text>
        </Container>
        <Container height="40px" width="40px" p="0" flex="0 0 40px">
          {collectChoice === index && (
            <Flex
              p="0"
              background={checkBgColor}
              align="center"
              justifyContent="center"
              borderRadius="full"
              h="100%"
            >
              <FiCheck />
            </Flex>
          )}
        </Container>
      </Stack>
    </Button>
  )
}

export default CardButton
